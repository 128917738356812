import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Company } from './companies.services';

export interface User {
  userUUID: string;
  companyUUID: string;
  fullName: string;
  email: string;
  password: string;
  isRoot: boolean | number;
  accountType: string;
  accountState: "active" | "inactive" | "blocked";
  createdAt: string;
  company: Company;
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) {}

  getUsers({ companyUUID } : { companyUUID?: string }) {
    return this.http.get<{ users: User[] }>(`${environment.apiUrl}users?${companyUUID? `companyUUID=${companyUUID}` : ''}`);
  }

  getUser(userUUID: string) {
    return this.http.get<{ user: User }>(`${environment.apiUrl}users/${userUUID}`)
  }

  postUser(body){
    return this.http.post<{ user: User }>(`${environment.apiUrl}users`, body)
  }

  putUser(userUUID: string, body){
    return this.http.put(`${environment.apiUrl}users/${userUUID}`, body)
  }

  deleteUser(userUUID: string){
    return this.http.delete(`${environment.apiUrl}users/${userUUID}`)
  }
}