import { NavMenu } from '@app/shared/types/nav-menu.interface';

export const navConfiguration: NavMenu[] = [
  {
    path: '',
    title: 'Apps',
    translateKey: 'Apps',
    type: 'title',
    iconType: 'feather',
    icon: 'icon-grid',
    key: 'apps',
    submenu: [
      {
        path: '/dashboard',
        title: 'Reportes',
        translateKey: 'Reportes',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-home',
        key: 'dashboard',
        submenu: []
      },
      {
        path: '/apps/users',
        title: 'Usuarios',
        translateKey: 'Usuarios',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-user',
        key: 'apps.users',
        submenu: []
      },
      {
        path: '/apps/clients',
        title: 'Clientes',
        translateKey: 'Clientes',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-user',
        key: 'apps.clients',
        submenu: []
      },
      {
        path: '/apps/raffles',
        title: 'Rifas',
        translateKey: 'Rifas',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-layers',
        key: 'apps.groups',
        submenu: []
      },
    ]
  }
]